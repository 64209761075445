import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  //SectionHero,
  //SectionHowItWorks,
  //SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  Button,
} from '../../components';
import { TopbarContainer } from '../../containers';

import MySlider from '../../components/MySlider/MySlider';

import sharepar_icon from '../../assets/sharepar_icon.png';

import facebookImage from '../../assets/sharepar_facebook_1200x630.jpg';
import twitterImage from '../../assets/sharepar_twitter_600x314.jpg';
import css from './LandingPage.css';



export const LandingPageComponent = props => {
  const {
    //history,
    intl,
    //location,
    scrollingDisabled,
    currentUserListing,
    //currentUserListingFetched,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const callToActionLink =  (currentUserListing)?'SearchPage':'LoginPage';



  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <h1 className={css.tagline}>Dinge gemeinschaftlich nutzen.<br/>
                Mit Sharepar <span className={css.flow}>flow</span>.</h1>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                {/*   
                <div className={css.betaHint}>
                  <h2>Die App befindet sich in der Testphase!</h2>
                  <p>Bei Problemen oder Hinweisen wenden Sie sich bitte an <a href='mailto:testen@sharepar.com'>testen@sharepar.com</a></p>
                </div>
                */}
                <NamedLink name={callToActionLink} className={css.callToAction}>
                  <Button>Jetzt Objekt teilen...</Button>
                </NamedLink>
                <NamedLink name={callToActionLink} className={css.callToAction}>
                  <MySlider/>
                </NamedLink>
                
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
